import React, { Component } from "react";
// import VideoCell from "../components/videoCell";
import "../styles/typography.css";
import "../styles/main.css";

// import { collections } from "../../gatsby-node.js";
// import Logo from "../assets/svg/artbymannLogo.svg";
import HeaderSection, { headerHeight } from "../components/headerSection";
import VideoCell from "../components/videoCell";
import CollectionToggleRow from "../components/collectionToggleRow";
import CloseIcon from "../assets/images/closeIcon.png";
import collections from "../../collections.json";
import { Helmet } from "react-helmet";
// import { fs } from "fs";

// const fs = require("fs");
// const collections = JSON.parse(
//   fs.readFileSync("../../collections.json", "utf8")
// );
const coll1 = collections[0];

const pp = (title, item) => {
  console.log(`${title}: ${JSON.stringify(item, null, 2)}`);
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      showColl1: true,
      showSmallModeMenu: false,
      isSmallMode: false,
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    pp("coll1", coll1);
    coll1.pieces.map((piece) => {
      import(`../assets/thumbnails/${piece.thumbnailFilename}`).then(
        (thumbnail) => {
          document.getElementById(`artbymann${piece.title}_thumbnail`).src =
            thumbnail.default;
        }
      );
      import(`../assets/mp4s/${piece.mp4Filename}`).then((video) => {
        document.getElementById(`artbymann${piece.title}`).src = video.default;
      });
    });
  }

  updateDimensions() {
    const windowWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const windowHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    // console.log(windowWidth);
    const minLargeWidth = 800;
    const isSmallMode = windowWidth < minLargeWidth;
    this.setState({ windowWidth, windowHeight, isSmallMode });
  }

  render() {
    const { windowHeight, windowWidth } = this.state;

    const menuWidth = 375;

    const universalPadding = 0.05 * windowWidth;

    const scrollWidth = this.state.isSmallMode
      ? windowWidth - universalPadding * 2
      : windowWidth - menuWidth;

    const genVideoCell = (piece, i) => {
      return (
        <VideoCell
          key={piece.title}
          listIndex={i}
          title={piece.title}
          collection={coll1.title}
          cellID={`artcell${piece.title}`}
          videoID={`artbymann${piece.title}`}
          thumbnailID={`artbymann${piece.title}_thumbnail`}
          artFrameWidth={(scrollWidth - universalPadding) / 2}
          artHPadding={universalPadding}
          windowWidth={windowWidth}
          show={this.state.showColl1}
          isSmallMode={this.state.isSmallMode}
        ></VideoCell>
      );
    };
    return (
      <>
        <Helmet>
          <script>
            {`
          (function() {
            function setZoomForSmallScreen() {
              var zoomLevel = window.innerWidth < 319 ? 0.5 : 1;
              document.body.style.zoom = zoomLevel;
            }
            
            window.addEventListener('load', setZoomForSmallScreen);
            window.addEventListener('resize', setZoomForSmallScreen);
          })();
        `}
          </script>
        </Helmet>
        <div>
          <div
            style={{
              position: "fixed",
              right: 0,
              top: 0,
              height: "100%",
              width: menuWidth - 100,
              background: "white",
              zIndex: 999,
              paddingLeft: this.state.isSmallMode ? universalPadding : 0,
              borderLeft: this.state.isSmallMode ? "1px solid" : "",
              // opacity: 0.75,
              display:
                this.state.isSmallMode && !this.state.showSmallModeMenu
                  ? "none"
                  : "block",
            }}
          >
            <div
              style={{
                position: "absolute",
                fontFamily: "SFMono",
                fontSize: 12,
                opacity: 0.8,
                top: 110,
                right: 20,
              }}
            >
              Latent space generations created by Luke Mann. These pieces are
              created by traversing the latent space of Generative Adversarial
              Networks. The model architecture was inspired by{" "}
              <a href="https://arxiv.org/abs/1710.10196" target="_blank">
                PGGAN
              </a>{" "}
              (Kerras et. al) and{" "}
              <a href="https://arxiv.org/abs/1809.11096" target="_blank">
                BigGAN
              </a>{" "}
              (Brock et. al).
            </div>
            {this.state.isSmallMode ? (
              <img
                src={CloseIcon}
                onClick={() => {
                  this.setState({ showSmallModeMenu: false });
                }}
                style={{
                  position: "absolute",
                  top: 50,
                  right: universalPadding,
                  height: 50,
                  width: 50,
                }}
              ></img>
            ) : (
              <div></div>
            )}

            {/* <div
            style={{
              fontFamily: "SFMono",
              fontSize: 19,
              marginTop: headerHeight + 5,
              opacity: 0.8,
            }}
          >
            Collections
          </div>
          <div style={{ marginTop: 12 }}>
            {
              <CollectionToggleRow
                selected={this.state.showColl1}
                collectionTitle={coll1.title}
                onToggle={() => {
                  this.setState({ showColl1: !this.state.showColl1 });
                }}
              ></CollectionToggleRow>
            }
          </div>*/}
          </div>
          <div
            style={{
              marginLeft: universalPadding,
              marginRight: universalPadding,
              width: scrollWidth,
            }}
          >
            {
              <HeaderSection
                universalPadding={universalPadding}
                onClick={() => {
                  this.setState({
                    showSmallModeMenu: !this.state.showSmallModeMenu,
                  });
                }}
                showMenuButton={this.state.isSmallMode}
              ></HeaderSection>
            }
            <div style={{ marginLeft: -2 }}>
              {coll1.pieces.map((piece, i) => {
                const a = genVideoCell(piece, i);
                return a;
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomePage;
