import PropTypes from "prop-types";
import React, { useState } from "react";
import playIcon from "../assets/images/playIcon.png";
import pauseIcon from "../assets/images/pauseIcon.png";
import "../styles/main.css";
import "../styles/typography.css";
import thumbnailPlaceholder from "../assets/images/thumbnailPlaceholder.png";
import loadingGIF from "../assets/images/loading.gif";

const VideoCell = (props) => {
  const newWidth = props.artFrameWidth * 0.9;
  const styles = {
    artFrame: {
      display: props.show ? "inline-block" : "none",
      width: newWidth,
      marginLeft: `${props.listIndex % 2 == 1 ? props.artHPadding : 0}px`,
      // paddingRight: `${props.artHPadding}px`,
      background: "white",
      marginBottom: `${props.artHPadding * 2}px`,
      fontFamily: "DIN",
      boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.25)",
    },
  };

  const [isPaused, setPaused] = useState(1);
  // const [vidIsLoading, setVidIsLoading] = useState(0);

  const play = () => {
    setPaused(false);
    if (document.getElementById(props.videoID).hidden) {
      document.getElementById(props.videoID).hidden = false;
      document.getElementById(props.thumbnailID).hidden = true;
    }
    var vid = document.getElementById(props.videoID);
    vid.play();
  };
  const pause = () => {
    setPaused(true);
    var vid = document.getElementById(props.videoID);
    vid.pause();
  };

  const togglePausePlay = () => {
    // if (document.getElementById(props.videoID).hidden) {
    //   document.getElementById(props.videoID).hidden = false;
    //   document.getElementById(props.thumbnailID).hidden = true;
    // }
    // setPaused(!isPaused);
    // var vid = document.getElementById(props.videoID);
    if (isPaused) {
      play();
    } else {
      pause();
    }
  };

  return (
    <div
      style={styles.artFrame}
      id={props.cellID}
      onMouseEnter={() => {
        if (!props.isSmallMode) play();
      }}
      onMouseLeave={() => {
        if (!props.isSmallMode) pause();
      }}
    >
      <video
        hidden
        onClick={togglePausePlay}
        id={props.videoID}
        loop
        muted
        style={{
          width: newWidth,
          height: newWidth,
          // boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.25)",
          cursor: "pointer",
        }}
      ></video>
      <img
        onClick={togglePausePlay}
        id={props.thumbnailID}
        src={thumbnailPlaceholder}
        style={{
          width: newWidth,
          height: newWidth,
          // boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.25)",
          cursor: "pointer",
        }}
      ></img>
      <div
        style={{
          marginTop: 24,
          marginBottom: 24,
          position: "relative",
          cursor: "default",
          paddingLeft: 0.05 * newWidth,
          // paddingRight: 0.1 * newWidth,
        }}
      >
        <div>
          <a
            href={`/view/${props.title.toLowerCase()}`}
            style={{ fontSize: 24, color: "black" }}
          >
            {props.title}
          </a>
          <div
            style={{
              fontSize: 19,
              marginTop: 2,
              color: "#717171",
            }}
          >
            {props.collection}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            height: props.isSmallMode ? 25 : 50,
            width: props.isSmallMode ? 25 : 50,
            right: 0.05 * newWidth,
            top: 0,
            borderRadius: props.isSmallMode ? 25 : 50,
            background: "black",
            cursor: "pointer",
          }}
          onClick={togglePausePlay}
        >
          <img
            src={!isPaused ? pauseIcon : playIcon}
            style={{
              height: props.isSmallMode ? 9 : 12,
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: props.isSmallMode ? 8 : 19,
            }}
          ></img>
        </div>
        <div></div>
      </div>
    </div>
  );
};

VideoCell.propTypes = {
  videoSrc: PropTypes.string,
  videoID: PropTypes.string,
  artFrameWidth: PropTypes.number,
  artHPadding: PropTypes.number,
  windowWidth: PropTypes.number,
  cellID: PropTypes.string,
  thumbnailID: PropTypes.string,
  title: PropTypes.string,
  collection: PropTypes.string,
  listIndex: PropTypes.number,
  show: PropTypes.bool,
  isSmallMode: PropTypes.bool,
  // piece: PropTypes.object,
};

export default VideoCell;
