import PropTypes from "prop-types";
import React, { useState } from "react";
import playIcon from "../assets/images/playIcon.png";
import pauseIcon from "../assets/images/pauseIcon.png";
import "../styles/main.css";
import thumbnailPlaceholder from "../assets/images/thumbnailPlaceholder.png";
import loadingGIF from "../assets/images/loading.gif";
import MenuIcon from "../assets/images/menuIcon.png";

export const headerHeight = 250;

const HeaderSection = (props) => {
  const styles = {};

  return (
    <div style={{ height: headerHeight, ...props.style }}>
      <div style={{ height: 128 }}></div>
      <a
        href={"/"}
        style={{
          display: "block",
          fontFamily: "DIN",
          fontSize: 46,
        }}
      >
        LATENTART
      </a>
      <div
        style={{
          height: 1,
          background: "black",
          width: "90%",
          marginTop: 10,
        }}
      ></div>
      <img
        onClick={props.onClick}
        hidden={!props.showMenuButton}
        style={{
          position: "absolute",
          right: props.universalPadding,
          top: 135,
          width: 40,
        }}
        src={MenuIcon}
      ></img>
    </div>
  );
};

HeaderSection.propTypes = {
  showMenuButton: PropTypes.bool,
  onToggleMenuButton: () => {},
  universalPadding: PropTypes.number,
};

export default HeaderSection;
